import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
// import { EventList } from '/src/components/events'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const DistributionPage = () => {
  return (
    <Layout hero={<HeroImage title="Distribution" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <Row>
            <Col sm={12}>
              <p>
                Orrcon Steel has two Australian manufacturing sites - A structural tubular mill in Brisbane and a precision tubing mill in Adelaide.
              </p>
              <p>
                Our manufacturing processes meet the highest standards and quality assurance including ISO 9001.
                <br></br>
                <Link to="/resources/certificates">(Click here for quality certificates)</Link>
              </p>
              <p>
                Whether it's specialised tight tolerance, high bending quality Precision Tube, 
                corrosion resistant in-line Electro Galvanised ALLGAL Tubulars (with Zinc both inside and outside the Section) or 
                high grade steel API Linepipe for high pressure gas pipelines, 
                when you contact Orrcon Steel for advice or assistance you get to the right person with the right answers.
              </p>
            </Col>
          </Row>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col sm={6}>
              <StaticImage 
                src="../../images/orrcon-group.jpeg" 
                alt="Distribution"
                width={570}
                height={384}
                quality={100}
                placeholder="blurred"
                layout="fullWidth" />
            </Col>
            <Col sm={6}>
              <p>Orrcon Steel's distribution centres are strategically located in Queensland, New South Wales, Victoria, South Australia, 
              Northern Territory and Western Australia, providing a national distribution network.</p>
              <p>Our modern warehouses provide an efficient service for the supply and delivery of a wide range of steel products and accessories.</p>
              <p>This network is complemented by more than 36 stockists / Metalcorp branches spread throughout metropolitan and rural Australia. 
              These stockists carry a wide range of Orrcon Steel products and accessories.</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-5">
        <Col xs={10}>
          <div className="title-container mb-5">
            <h1 className="title">
              We support local communities by providing
            </h1>
          </div>
          <LinkedList items={[
            {
              content: <LinkedListItem 
                title="DISTRIBUTION"
                content="" />,
              path: '/community/distribution'
            },
            {
              content: <LinkedListItem 
                title="RANGE"
                content="" />,
              path: '/community/range'
            },
            {
              content: <LinkedListItem 
                title="MANUFACTURING"
                content="" />,
              path: '/community/manufacturing'
            }
          ]} />
        </Col>
      </Row>
    </Layout>
  )
}

export default DistributionPage

export const Head = () => <title>Distribution | Orrcon Steel</title>
